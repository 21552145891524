export * from './layout';
export * from './pagePath';

export const apiServiceUrl = 'https://api-old.prove-it-to.me/api';

export const apiSuccessfulCode = 200;
export const apiInternalErrorCode = 500;

export const defaultErrorMessage = "Unknown error occurred."

export const reactStripeApp = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ??
    'pk_test_51HB3cwFbXJwPcX88kaOLEH5y7ouEb5jKC3n4lqReIPiyNO82PssSo8EQGB2MOZmWBSVDWFZOywjNUSshyCMfKPUN00VqtrowaO';

