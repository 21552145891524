import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';

import * as Actions from './actions';
import * as ActionType from './constants';
import {
    fetchGetMethod,
    fetchPostMethod,
    fetchPostMethodWithFormData,
    GetErrorMessageFromStatus
} from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { apiServiceUrl } from '../../constants';

const userApiServiceUrl = `${apiServiceUrl}/user`
const paymentApiServiceUrl = `${apiServiceUrl}/payment`

function* updateProfilePassword({ payload: { currentPassword, newPassword }}) {
    try {
        const user = getLoggedInUser();
        const body = {
            CurrentPassword: currentPassword,
            NewPassword: newPassword
        }
        const response = yield call(
            fetchPostMethod,
            `${userApiServiceUrl}/ChangePassword`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
                const { errorMessage, succeeded } = yield response.data.then((response) => {
                return {
                    succeeded: response.succeeded,
                    errorMessage: response.errorMessage
                };
            }).catch(() => ({ status: 500 }));

            if (succeeded) {
                yield put(Actions.updateProfilePasswordSuccess());
            } else {
                const message = errorMessage ?? "Error trying to update password";
                yield put(Actions.updateProfilePasswordFailed(message));
            }
        } else {
            yield put(Actions.updateProfilePasswordFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.updateProfilePasswordFailed(GetErrorMessageFromStatus(error)));
    }
}

function* loadProfileInformation() {
    try {
        const user = getLoggedInUser();
        const response = yield call(fetchGetMethod, `${userApiServiceUrl}/LoadPersonalInformation`, user.token);

        if (response.status === 200) {
            const { profile, status } = yield response.data.then((response) => {
                return { profile: response.profile };
            }).catch(() => ({ status: 500 }));
            if (profile) {
                yield put(Actions.loadProfileInformationSuccess(profile));
            } else {
                yield put(Actions.loadProfileInformationFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.loadProfileInformationFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.loadProfileInformationFailed(GetErrorMessageFromStatus(error)));
    }
}

function* updateProfileInformation({ payload: {
    name, companyName, phoneNumber, city, state, country, codeId, groupId, email, zipCode, address, logo
}}) {
    const data = new FormData();
    data.append('Name', name);
    data.append('CompanyName', companyName);
    data.append('City', city);
    data.append('State', state);
    data.append('Country', country);
    data.append('PhoneNumber', phoneNumber);
    data.append('CodeId', codeId);
    data.append('GroupId', groupId);
    data.append('Email', email);
    data.append('ZipCode', zipCode);
    data.append('Address', address);
    data.append('logo', logo);
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchPostMethodWithFormData,
            `${userApiServiceUrl}/SavePersonalInformation`,
            user.token,
            data
        );

        if (response.status === 200) {
            const { profile, status } = yield response.data.then((response) => {
                return { profile: response.profile };
            }).catch(() => ({ status: 500 }));
            if (profile) {
                yield put(Actions.updateProfileInformationSuccess(profile));
            } else {
                yield put(Actions.updateProfileInformationFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.updateProfileInformationFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.updateProfileInformationFailed(GetErrorMessageFromStatus(error)));
    }
}

function* loadUserSubscriptionInformation() {
    try {
        const user = getLoggedInUser();
        const response = yield call(fetchGetMethod, `${paymentApiServiceUrl}/GetSubscriptions`, user.token);

        if (response.status === 200) {
            const { subscriptions, status } = yield response.data.then((response) => {
                return { subscriptions: response };
            }).catch(() => ({ status: 500 }));
            if (subscriptions) {
                yield put(Actions.loadUserSubscriptionInformationSuccess(subscriptions));
            } else {
                yield put(Actions.loadUserSubscriptionInformationFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.loadUserSubscriptionInformationFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.loadUserSubscriptionInformationFailed(GetErrorMessageFromStatus(error)));
    }
}

function* cancelSubscription({ payload: { newPlanId }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchPostMethod,
            `${paymentApiServiceUrl}/CancelSubscription`,
            user.token,
            {}
        );

        if (response.status === 200) {
            const { message } = yield response.data.then((response) => ({ message: response }));
            if (message) {
                yield put(Actions.cancelSubscriptionSuccess(message, newPlanId));
            } else {
                yield put(Actions.cancelSubscriptionFailed(GetErrorMessageFromStatus(response)));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response }));
            yield put(Actions.cancelSubscriptionFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.cancelSubscriptionFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.cancelSubscriptionFailed(GetErrorMessageFromStatus(error)));
    }
}

function* upgradeOrDowngradeSubscription({ payload: { newPlanId }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchPostMethod,
            `${paymentApiServiceUrl}/UpgradeOrDowngradeSubscription`,
            user.token,
            {}
        );

        if (response.status === 200) {
            const { message } = yield response.data.then((response) => ({ message: response }));
            if (message) {
                yield put(Actions.upgradeOrDowngradeSubscriptionSuccess(message, newPlanId));
            } else {
                yield put(Actions.upgradeOrDowngradeSubscriptionFailed(GetErrorMessageFromStatus(response)));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response }));
            yield put(Actions.upgradeOrDowngradeSubscriptionFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.upgradeOrDowngradeSubscriptionFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.upgradeOrDowngradeSubscriptionFailed(GetErrorMessageFromStatus(error)));
    }
}

function* updateSuccessfulPlan({ payload: { newPlanId }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchPostMethod,
            `${paymentApiServiceUrl}/UpdateSuccessfulPlan`,
            user.token,
            {}
        );

        if (response.status === 200) {
            const { message } = yield response.data.then((response) => ({ message: response }));
            if (message) {
                yield put(Actions.updateSuccessfulPlanSuccess(message, newPlanId));
            } else {
                yield put(Actions.updateSuccessfulPlanFailed(GetErrorMessageFromStatus(response)));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response }));
            yield put(Actions.updateSuccessfulPlanFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.updateSuccessfulPlanFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.updateSuccessfulPlanFailed(GetErrorMessageFromStatus(error)));
    }
}

export function* watchUpdateProfilePassword(): any {
    yield takeLatest(ActionType.UPDATE_PROFILE_PASSWORD, updateProfilePassword);
}

export function* watchLoadProfileInformation(): any {
    yield takeLatest(ActionType.LOAD_PROFILE_INFORMATION, loadProfileInformation);
}

export function* watchUpdateProfileInformation(): any {
    yield takeLatest(ActionType.UPDATE_PROFILE_INFORMATION, updateProfileInformation);
}

export function* watchLoadUserSubscriptionInformation(): any {
    yield takeLatest(ActionType.LOAD_USER_SUBSCRIPTION_INFORMATION, loadUserSubscriptionInformation);
}

export function* watchCancelSubscription(): any {
    yield takeLatest(ActionType.CANCEL_SUBSCRIPTION, cancelSubscription);
}

export function* watchUpgradeOrDowngradeSubscription(): any {
    yield takeLatest(ActionType.UPGRADE_OR_DOWNGRADE_SUBSCRIPTION, upgradeOrDowngradeSubscription);
}

export function* watchUpdateSuccessfulPlan(): any {
    yield takeLatest(ActionType.UPDATE_SUCCESSFUL_PLAN, updateSuccessfulPlan);
}

function* userSaga(): any {
    yield all([
        fork(watchUpdateProfilePassword),
        fork(watchLoadProfileInformation),
        fork(watchUpdateProfileInformation),
        fork(watchLoadUserSubscriptionInformation),
        fork(watchCancelSubscription),
        fork(watchUpgradeOrDowngradeSubscription),
        fork(watchUpdateSuccessfulPlan),
    ]);
}

export default userSaga;
