import { all, call, fork, put, takeLatest } from '@redux-saga/core/effects';

import * as Actions from './actions';
import * as ActionType from './constants';
import { fetchGetMethod, GetErrorMessageFromStatus } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { apiServiceUrl } from '../../constants';

const dashboardApiServiceUrl = `${apiServiceUrl}/dashboard`

function* loadDashboardData() {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${dashboardApiServiceUrl}/GetMyDashboard`,
            user.token,
        );
        if (response.status === 200) {
            const {
                vpoTotalImplementation,
                vpoTotalForecast,
                vpoTotalProposition,
                totalClients,
                totalValueProposition,
                totalPortifolioAmount,
                totalFundamentals,
                valuePropositionSummary,
                status
            } = yield response.data.then((response) => {
                return (response.Status === 200)
                    ? (
                        {
                            vpoTotalImplementation: response.VpoTotalImplementation,
                            vpoTotalForecast: response.VpoTotalForecast,
                            vpoTotalProposition: response.VpoTotalProposition,
                            totalClients: response.TotalClients,
                            totalValueProposition: response.TotalValueProposition,
                            totalPortifolioAmount: response.TotalPortifolioAmount,
                            totalFundamentals: response.TotalFundamentals,
                            valuePropositionSummary: response.ValuePropositionSummary,
                        }
                    ) : { status: response.Status };
            }).catch(() => ({ status: 500 }));
            if (
                vpoTotalImplementation && vpoTotalForecast && vpoTotalProposition &&
                totalClients && totalValueProposition && valuePropositionSummary
            ) {
                yield put(
                    Actions.loadDashboardDataSuccess(
                        vpoTotalImplementation,
                        vpoTotalForecast,
                        vpoTotalProposition,
                        totalClients,
                        totalValueProposition,
                        totalPortifolioAmount,
                        totalFundamentals,
                        valuePropositionSummary
                    )
                );
            } else {
                yield put(Actions.loadDashboardDataFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.loadDashboardDataFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.loadDashboardDataFailed(GetErrorMessageFromStatus(error)));
    }
}

export function* watchLoadDashboardData(): any {
    yield takeLatest(ActionType.LOAD_DASHBOARD_DATA, loadDashboardData);
}

function* dashboardSaga(): any {
    yield all([
        fork(watchLoadDashboardData),
    ]);
}

export default dashboardSaga;
